import React, { useState, useEffect } from 'react';
import JobCard from './JobCard';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Search = () => {
    const [results, setResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const { category } = useParams(); 
    
    useEffect(() => {
        fetch('https://frazzled-api.azurewebsites.net/api/v1/search?category=' + category)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setResults(data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [category]);

    // Function to handle input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value); // Update the search query state
    };

    // Filter the results based on the search query
    const filteredResults = results.filter((item) =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Function to add spaces before capital letters
    const addSpaceBeforeCapital = (str) => {
        return str.replace(/([a-z])([A-Z])/g, '$1 $2');
    };

    // Format the category for display
    const formattedCategory = addSpaceBeforeCapital(category);

    return (
        <>
            <Helmet>
                <title>Scores by {formattedCategory} - Frazzled</title>
                <meta name="description" content="A list of job titles by happiness" />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="container-fluid">
                <div className="row">
                    <div className="col mt-5 mb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <h2 className="mb-4 text-center fw-bold">Search by {formattedCategory}</h2>
                                    {/* Search input field */}
                                    <input
                                        type="text"
                                        value={searchQuery}
                                        onChange={handleSearchChange} // Call handler function
                                        placeholder="Search..."
                                        className="form-control mb-4"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{
                    backgroundImage: 'url("/background.svg")',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    minHeight: "1200px", // Minimum height
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center" // Center content vertically
                }}>
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    {filteredResults.length > 0 ? (
                                        filteredResults.map((item, index) => (
                                            <div key={index} className="row justify-content-center mt-4 mb-4">
                                                <JobCard
                                                    title={item.title}
                                                    subtitle={item.subtitle}
                                                    reviews={item.reviews}
                                                    battery={item.score}
                                                    parameter={item.parameter}
                                                    id={item.id}
                                                />
                                            </div>
                                        ))
                                    ) : (
                                        <h4 className="text-center mt-5 fw-bold">No results found</h4>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Search;