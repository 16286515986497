// src/SearchableSuggestionField.js
import React, { useState, useEffect } from 'react';


const SearchableTextField = ({ apiEndpoint, searchField, displayTextProperty, idProperty, setValue }) => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suppressChange, setSuppressChange] = useState(false);
    const [chosen, setChosen] = useState(false);

    useEffect(() => {
      if (inputValue.length > 0 && chosen === false) {
          fetch(apiEndpoint + '?' + searchField + '=' + inputValue)
              .then((response) => {
                  if (!response.ok) {
                      throw new Error('Network response was not ok');
                  }
                  return response.json();
              })
              .then((data) => {
                  setSuggestions(data);
                  setShowSuggestions(true);
              })
              .catch((error) => {
                  // Handle error
                  setShowSuggestions(false);
              });
      } else {
          setShowSuggestions(false);
      }
  }, [inputValue, apiEndpoint, searchField, chosen]); 

    const handleChange = (e) => {
        if (suppressChange === false) {
            setChosen(false);
            setInputValue(e.target.value);
            setValue(e.target.value)
        }
    };

    const handleSuggestionClick = (selectedItem) => {
        setChosen(true);
        setSuppressChange(true);
        setInputValue(selectedItem[displayTextProperty]);
        setShowSuggestions(false);
        setSuppressChange(false);
        setValue(selectedItem[displayTextProperty])
    };

    return (
        <div class="suggestion-textbox mb-3">
            <input
                type="text"
                value={inputValue}
                onChange={handleChange}
                placeholder="Search..."
                class="form-control"
            />
            {showSuggestions && (
                <ul>
                    {suggestions.map(listItem => (
                        <li key={listItem[idProperty]} onClick={() => handleSuggestionClick(listItem)}>
                            {listItem[displayTextProperty]}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchableTextField;
