import React from 'react';
import { Link } from 'react-router-dom';

const JobCard = ({ title, subtitle, reviews, battery, id, parameter}) => {
    const batteryImage = `${process.env.PUBLIC_URL}/batteries/${battery}.svg`;

    return (
        <div className="col">
            <div className="bg-white p-4 shadow-lg">
                <div className="row align-items-center">
                    <div className="col-sm-12 col-md text-start">
                        <h5>{title}</h5>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xl-2 col-6 justify-content-end">
                            <div className="d-md-none text-start">
                                <h5 className="mb-1">{reviews}</h5> {/* Left-aligned on mobile */}
                                <p className="mb-0">Reviews</p> {/* Left-aligned on mobile */}
                            </div>
                            <div className="d-none d-md-flex flex-column align-items-center">
                                <h5 className="mb-1">{reviews}</h5> {/* Centered on larger screens */}
                                <p className="mb-0">Reviews</p> {/* Centered on larger screens */}
                            </div>
                    </div>
                    <div className="col-xs-8 col-md-2 col-lg-2 col-xl-1 col-6 d-flex justify-content-md-start justify-content-end">
                        <img src={batteryImage} alt={`${battery}% battery`} className="battery-icon" />
                    </div>
                    <div className="col-md-3 col-lg-2 col-xl-2 text-end d-none d-md-block">
                        <Link className="btn fw-bold" to={"/statistics/?parameter=" + parameter + "&id=" + id} style={{ backgroundColor: '#EA526F', color: '#FFFFFF', width: 125 }}>See Scores</Link>
                    </div>
                </div>
                <div className="d-md-none text-end mt-4">
                    <Link className="btn fw-bold mt-2" to={"/statistics/?parameter=" + parameter + "&id=" + id}  style={{ backgroundColor: '#EA526F', color: '#FFFFFF', width: '100%' }}>See Scores</Link>
                </div>
            </div>
        </div>
    );
}

export default JobCard;