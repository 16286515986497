import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>Burnout Test - Frazzled</title>
                <meta name="description" content="Discover where happiness really is in your career. Take the test and fix your burnout." />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="container-fluid">
                <div className="row" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col mt-5 mb-5 bg-white d-flex align-items-center">
                                    <div className="row m-5 w-100">
                                        <div className="col-lg-6 col-12 d-flex flex-column justify-content-center text-center text-lg-start">
                                            <h1 className="d-none d-md-block" style={{ fontSize: '4.5em', marginLeft: '-0.2rem' }}>Burnt out?</h1>
                                            <h1 className="d-block d-md-none" style={{ fontSize: '3em', marginLeft: '-0.2rem', marginBottom: '2rem' }}>Burnt out?</h1>
                                            <h3 className="mb-4" style={{ marginTop: '-0.5rem' }}>Let's change that</h3>
                                            <p>1. Compare how you feel to others in your field</p>
                                            <p>2. Help improve happiness where you work</p>
                                            <p>3. Discover the happiest career paths</p>
                                            <Link to="/test" className="btn btn-danger text-white fw-bold mt-3 shadow mx-auto mx-lg-0" style={{ backgroundColor: '#EA526F', color: '#FFFFFF', width: 135 }}>Take the Test</Link>
                                        </div>
                                        <div className="col d-flex justify-content-end">
                                            <div className="white-box d-none d-lg-block">
                                                <img src="brain.svg" className="img-fluid mt-3" alt="frazzled-brain" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center mt-5 mb-5">
                        <div className="col-md-8 text-center">
                            <h3 className="mt-5">Discover where happiness really is</h3>
                            <p className="mt-1">
                                Every wondered what makes a happy job? Search by category
                            </p>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-5">
                        <div className="col-6 col-md-2 text-center">
                            <img src="/icons/dollarsign.circle.svg" alt="Money Bag" className="img-fluid" />
                            <p className="mt-2">Salary Bracket</p>
                        </div>
                        <div className="col-6 col-md-2 text-center">
                            <img src="/icons/birthday.cake.svg" alt="Birthday Cake" className="img-fluid" />
                            <p className="mt-2">Age</p>
                        </div>
                        <div className="col-6 col-md-2 text-center">
                            <img src="/icons/person.svg" alt="Job Title" className="img-fluid" />
                            <p className="mt-2">Job Title</p>
                        </div>
                        <div className="col-6 col-md-2 text-center">
                            <img src="/icons/building.columns.svg" alt="Department" className="img-fluid" />
                            <p className="mt-2">Department</p>
                        </div>
                        <div className="col-6 col-md-2 text-center">
                            <img src="/icons/house.svg" alt="Remote or Hybrid" className="img-fluid" />
                            <p className="mt-2">Remote or Hybrid</p>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                {/*<h2 className="mt-5 text-center fw-bold">See how your scores compare to others</h2>*/}
                                <div className="col-lg-6 col-md-12 mt-5 mb-lg-5 mb-0">
                                    <div class="bg-white shadow-lg h-100 w-100 d-flex align-items-center justify-content-center p-4">
                                        <div className="text-center">
                                            <h3 className="w-100">Find out your Health Score</h3>
                                            <img src="/batteries/40.svg" alt="battery-icon" className="my-3" style={{ maxHeight: 175 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 mt-lg-5 mt-3 mb-5">
                                    <div className="bg-white shadow-lg text-center w-100 mb-3 p-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="text-container me-5">
                                                <h3 className="text-title">Job Title<br />Average Score</h3>
                                            </div>
                                            <img src="/batteries/25.svg" alt="battery-icon" className="my-3 ms-3" style={{ maxHeight: 80 }} />
                                        </div>
                                    </div>
                                    <div className="bg-white shadow-lg text-center w-100 p-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="text-container me-5">
                                                <h3 className="text-title">Frazzled<br />Average Score</h3>
                                            </div>
                                            <img src="/batteries/62.svg" alt="battery-icon" className="my-3 ms-3" style={{ maxHeight: 80 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center mt-5 mb-5">
                        <div className="col-md-8 text-center m-5">
                            <h3 className="mt-5">Our mission</h3>
                            <h1 className="mt-4" style={{ fontSize: '2.5em', fontStyle: 'italic' }}>"To improve everyone's happiness at work"</h1>
                            <p className="mt-4 mb-5">
                                We aim to eliminate toxic workplaces by collecting and analyzing employee happiness data.
                                We provide anonymized insights to help companies improve, and highlight those excelling in employee happiness.
                                Our goal is to drive continuous improvement across all industries, so everyone can find more joy and fulfillment at work.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;