import React, { useState } from 'react';
import { Row, Col, Accordion, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet-async';

const Privacy = () => {
    const [activeSection, setActiveSection] = useState('guidelines-for-reviewers');

    const renderContent = () => {
        switch (activeSection) {
            case 'guidelines-for-reviewers':
                return (
                    <>
                        <h1 className="mb-4">Guidelines for Reviewers</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            Welcome to the Frazzled. As a reviewer, you play a critical role in ensuring the integrity and authenticity of the data collected from our tests. Your careful review helps maintain the quality of our insights and helps us change the workplace for the better. Here are some guidelines on when to take the test, the importance of honest answers, how to edit or delete your results, and what not to do during the review process.
                        </p>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>When to take the test</Accordion.Header>
                                <Accordion.Body>
                                    Reviewers should take the test periodically to ensure data relevance. This helps in making more informed decisions when reviewing test data. It is recommended to take the test at least once every quarter or after any significant updates to your job.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Don't give fake or biased answers</Accordion.Header>
                                <Accordion.Body>
                                    It is crucial that all answers provided during the review process are genuine and unbiased. Fake or biased answers can compromise the quality of the data and lead to inaccurate insights. Reviewers must evaluate data objectively and flag any responses that seem intentionally misleading or prejudiced.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Edit or delete your results</Accordion.Header>
                                <Accordion.Body>
                                    If you need to edit or delete any of your own test results, please contact us using the form below.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Red Flags</Accordion.Header>
                                <Accordion.Body>
                                    There are some important rules for reviewers to follow, if found they will be blocked from taking the test ever again and the data will be deleted.
                                    <ul>
                                        <li>Do not submit multiple test results in a short space of time (i.e more than 1 a month) in an effort to alter scores</li>
                                        <li>Do not ever attempt to reveal the identity of another reviewer. If you think you know another reviewer from the data, please tell us so we can protect their identity.</li>
                                        <li>Do not use the scores without crediting Frazzled or gaining permission.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                );
            case 'privacy-policy':
                return (
                    <>
                        <h1 className="mb-4">Privacy Policy</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you participate in our tests designed to quantitatively review burnout and happiness in your job and company.
                        </p>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Information We Collect</Accordion.Header>
                                <Accordion.Body>
                                    <p>We collect the following personal data when you participate in our tests:</p>
                                    <ul>
                                        <li>Company Name</li>
                                        <li>Job Title</li>
                                        <li>Department</li>
                                        <li>Salary Bracket</li>
                                        <li>Seniority</li>
                                        <li>Working Arrangement</li>
                                        <li>Years in Service</li>
                                        <li>Age</li>
                                        <li>Gender</li>
                                        <li>Sexual Orientation</li>
                                        <li>Ethnicity</li>
                                        <li>Email Address (for verification only)</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>How We Use Your Information</Accordion.Header>
                                <Accordion.Body>
                                    <p>We use the information we collect to:</p>
                                    <ul>
                                        <li>Analyze burnout and happiness levels across different job titles, salary brackets, companies, and demographics.</li>
                                        <li>Generate insights and reports that help improve workplace environments.</li>
                                        <li>Conduct internal checks to ensure the accuracy and authenticity of the data provided.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Data Protection and Security</Accordion.Header>
                                <Accordion.Body>
                                    We implement a variety of security measures to maintain the safety of your personal information. Your data is stored on secure servers and protected by encryption. We ensure that access to your data is limited to authorized personnel only.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Data Anonymity and Confidentiality</Accordion.Header>
                                <Accordion.Body>
                                    We take your privacy extremely seriously. Although we collect detailed personal information, we anonymise data for analysis purposes to ensure that individual identities are not revealed in our reports and insights. Internal checks are conducted to maintain data integrity and authenticity without compromising your anonymity.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Sharing Your Information</Accordion.Header>
                                <Accordion.Body>
                                    We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share anonymised and aggregated data with partners and stakeholders to help improve workplace environments. These reports will never identify any individuals.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Your Data Protection Rights</Accordion.Header>
                                <Accordion.Body>
                                    <p>You have the right to:</p>
                                    <ul>
                                        <li>Access the personal information we hold about you.</li>
                                        <li>Request correction of any incorrect or incomplete information.</li>
                                        <li>Request the deletion of your personal information.</li>
                                        <li>Object to the processing of your personal data.</li>
                                        <li>Request the restriction of processing of your personal data.</li>
                                        <li>Withdraw your consent at any time where we are relying on consent to process your personal data.</li>
                                    </ul>
                                    <p>If you wish to exercise any of these rights, please contact using the form below.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Changes To This Privacy Policy</Accordion.Header>
                                <Accordion.Body>
                                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                );
            case 'terms-of-use':
                return (
                    <>
                        <h1 className="mb-4">Terms of Use</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            These Terms of Use govern your access to and use of our test platform and related services. By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our services.
                        </p>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Eligibility</Accordion.Header>
                                <Accordion.Body>
                                    <p>To use our Services, you must be at least 18 years old and hold a permanent, contract or part time job with a company. By using our Services, you represent and warrant that you meet these eligibility requirements.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>User Responsibilities</Accordion.Header>
                                <Accordion.Body>
                                    <p>When using our Services, you agree to:</p>
                                    <ul>
                                        <li>Provide Accurate Information</li>
                                        <li>Maintain Confidentiality</li>
                                        <li>Respect Privacy</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Prohibited Content</Accordion.Header>
                                <Accordion.Body>
                                    <p>You agree not to:</p>
                                    <ul>
                                        <li>Use the Services for any unlawful purpose or in violation of any local, state, national, or international law.</li>
                                        <li>Submit false or misleading information.</li>
                                        <li>Impersonate any person or entity or misrepresent your affiliation with a person or entity.</li>
                                        <li>Engage in any conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm us or other users of the Services.</li>
                                        <li>Compromise the security of the platform</li>

                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Intellectual Property</Accordion.Header>
                                <Accordion.Body>
                                    All content, features, and functionality of the Services, including but not limited to text, graphics, logos, and software, are the exclusive property of Frazzled or its licensors and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Termination</Accordion.Header>
                                <Accordion.Body>
                                    We reserve the right to terminate or suspend your access to all or part of the Services, without notice, for any conduct that we, in our sole discretion, believe is in violation of these Terms or any applicable law or is harmful to the interests of other users or us.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Disclaimers</Accordion.Header>
                                <Accordion.Body>
                                    <p>The Services are provided on an "as-is" and "as-available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Services, or the information, content, or materials included therein. You expressly agree that your use of the Services is at your sole risk.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Limitation of Liability</Accordion.Header>
                                <Accordion.Body>
                                    <p>To the fullest extent permitted by law, Frazzled shall not be liable for any damages of any kind arising from the use of or inability to use the Services, including but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                );
            case 'guidelines-for-companies':
                return (
                    <>
                        <h1 className="mb-4">Guidelines for Companies</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            Our business product is not available yet but will be soon. Please contact us using the form below if you have interest.
                        </p>
                    </>
                );
            case 'content-guidelines':
                return (
                    <>
                        <h1 className="mb-4">Content Guidelines</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            If you would like to quote any of our stats, please contact us for permission using the form below.
                        </p>
                    </>
                );
            case 'legal-brand-guidelines':
                return (
                    <>
                        <h1 className="mb-4">Brand Guidelines</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            At this time companies are not permitted to use any of our branding. This might change in the future with the introduction of our products.
                        </p>
                    </>
                );
            case 'company-privacy-policy':
                return (
                    <>
                        <h1 className="mb-4">Company Privacy Policy</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            We will add a company specific privacy policy with the introduction of our business product.
                        </p>
                    </>
                );
            case 'code-of-ethics':
                return (
                    <>
                        <h1 className="mb-4">Code of Ethics</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            At Frazzled, we are committed to conducting our business with integrity, honesty, and transparency. Our Code of Ethics outlines the ethical principles that guide our actions and decisions.
                        </p>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Compliance with Laws and Regulations</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>Comply with all applicable laws, regulations, and industry standards.</li>
                                        <li>Submit false or misleading information.</li>
                                        <li>Report any suspected violations of laws or regulations.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Confidentiality and Privacy</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>Protect the confidentiality of proprietary and personal information.</li>
                                        <li>Respect the privacy rights of customers, employees, and partners.</li>
                                        <li>Use data responsibly and in accordance with our Privacy Policy.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Fair Competition</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>Engage in fair and honest competition.</li>
                                        <li>Avoid any anti-competitive practices or actions.</li>
                                        <li>Respect the intellectual property rights of others.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Conflict of Interest</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>Engage in fair and honest competition.</li>
                                        <li>Avoid any anti-competitive practices or actions.</li>
                                        <li>Respect the intellectual property rights of others.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                );
            case 'anti-bribery-corruption':
                return (
                    <>
                        <h1 className="mb-4">Anti Bribery/Corruption Statement</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            At Frazzled, we are committed to maintaining the highest standards of integrity and ethical conduct in all our business activities. We have a zero-tolerance policy towards bribery and corruption.
                        </p>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Prohibition of Bribery</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>We strictly prohibit the offering, giving, receiving, or soliciting of any bribe, whether in cash or any other form of inducement, to or from any individual or organization, including government officials, customers, suppliers, or business partners.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Compliance with Laws</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>We comply with all applicable anti-bribery and anti-corruption laws and regulations in every jurisdiction in which we operate.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Transparency and Accountability</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>We conduct all our business dealings transparently and uphold the principles of fairness and accountability.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Investigation</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>All reported cases of bribery or corruption will be promptly and thoroughly investigated. Appropriate disciplinary actions, including termination of employment, will be taken against those found to be in violation of this policy.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                );
            case 'cookie-policy':
                return (
                    <>
                        <h1 className="mb-4">Cookie Policy</h1>
                        <p className="text-danger fw-bold">July 2024</p>
                        <p>
                            At Frazzled ("we", "our", "us"), we use cookies and similar technologies to enhance your experience on our website. This Cookie Policy explains what cookies are, how we use them, and your choices regarding their use.
                        </p>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Essential Cookies:</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>These cookies are necessary for the website to function properly. They enable you to navigate the site and use its features.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Performance and Analytics Cookies</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. They allow us to improve the site’s performance and user experience.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Functional Cookies</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>These cookies allow our website to remember choices you make (such as your language or region) to provide a more personalized experience.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Advertising Cookies</Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        <li>We may use these cookies to deliver advertisements that are relevant to you and your interests. They also help measure the effectiveness of advertising campaigns.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Your Choices</Accordion.Header>
                                <Accordion.Body>
                                    <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by adjusting your browser settings. Most browsers allow you to:</p>
                                    <ul>
                                        <li>View the cookies that are stored on your device and delete them.</li>
                                        <li>Block cookies from being installed on your device.</li>
                                        <li>Block third-party cookies.</li>
                                        <li>Clear all cookies when you close your browser.</li>
                                    </ul>
                                    <p>Please note that if you choose to block or delete cookies, some parts of our website may not function properly.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <Helmet>
                <title>Privacy Policy - Frazzled</title>
                <meta name="description" content="Policies for how we operate the platform, for companies and people." />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="container-fluid" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                <div className="row h-100 align-items-center">
                    <div className="col"></div>
                    <div className="w-100 mt-lg-5 mb-3">
                        <Row className="h-100 justify-content-center align-items-center">
                            <Col xs={12} lg={10} className="bg-white shadow p-5" style={{ padding: '0' }}>
                                <Row noGutters>
                                    <Col xs={12} md={3} className="bg-white p-4">
                                        <Nav className="flex-column">
                                            <h5>For Reviewers</h5>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('guidelines-for-reviewers')}>Guidelines for Reviewers</Nav.Link>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('privacy-policy')}>Privacy Policy</Nav.Link>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('terms-of-use')}>Terms of Use</Nav.Link>
                                            <h5 className="mt-5">For Companies</h5>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('guidelines-for-companies')}>Guidelines for Companies</Nav.Link>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('content-guidelines')}>Content Guidelines</Nav.Link>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('legal-brand-guidelines')}>Brand Guidelines</Nav.Link>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('company-privacy-policy')}>Privacy Policy</Nav.Link>
                                            <h5 className="mt-5">For Everyone</h5>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('code-of-ethics')}>Code of Ethics</Nav.Link>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('anti-bribery-corruption')}>Anti-Bribery & Corruption</Nav.Link>
                                            <Nav.Link className="text-dark" onClick={() => setActiveSection('cookie-policy')}>Cookie Policy</Nav.Link>
                                        </Nav>
                                    </Col>
                                    <Col xs={12} md={9} className="ps-5 pe-5 pt-3 pb-3">
                                        {renderContent()}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="row h-100 align-items-center">
                    <div className="col"></div>
                    <div className="col-lg-10 col-md-10 col-10 mt-5 mb-5 bg-white p-5">
                        <h1 className="text-center mb-4">Contact Us</h1>
                        <form>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">Your Name *</label>
                                <input type="text" className="form-control" id="name" placeholder="Enter your name" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Your Email *</label>
                                <input type="email" className="form-control" id="email" placeholder="Enter your email" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="message" className="form-label">Message *</label>
                                <textarea className="form-control" id="message" rows="8" placeholder="Enter your message" required></textarea>
                            </div>
                            <button type="submit" className="btn fw-bold" style={{ backgroundColor: '#EA526F', color: '#FFFFFF', width: 80 }}>Submit</button>
                        </form>
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </>
    );
};

export default Privacy;