import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [status, setStatus] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send('service_92h8kzw', 'template_shvwedd', formData, 'R-nVheLjavIFnpHmQ')
            .then((response) => {
                console.log('Success:', response);
                setStatus('Your message has been sent, we will respond shortly.');
                // Clear the form
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                });

                // Manually reset form fields
                e.target.reset();
            }, (error) => {
                console.log('Error:', error);
                setStatus('Error sending message.');
            });
    };

    return (
        <>
            <Helmet>
                <title>Contact Us - Frazzled</title>
                <meta name="description" content="Contact us to talk about your burnout or if you have any questions." />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="row" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                <div className="col">
                    <div className="container">
                        <div className="col-12 mt-5 mb-5 bg-white p-5">
                            <h1 className="text-center mb-2">Contact Us</h1>
                            <h6 className="text-center mb-4">or email us directly: info@frazzled.work</h6>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Your Name *</label>
                                    <input type="text" className="form-control" id="name" value={formData.name} onChange={handleChange} placeholder="Enter your name" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Your Email *</label>
                                    <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label">Message *</label>
                                    <textarea className="form-control" id="message" value={formData.message} onChange={handleChange} rows="12" placeholder="Enter your message" required></textarea>
                                </div>
                                <div className="text-end">
                                    <button type="submit" className="btn fw-bold" style={{ backgroundColor: '#EA526F', color: '#FFFFFF', width: 80 }}>Submit</button>
                                </div>
                            </form>
                            {status && <p className="text-center mt-3 fw-bold">{status}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;