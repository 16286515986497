import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const articles = [
    {
        id: 1,
        title: "Decoded: What Your Frazzled Test Results Really Say About You",
        summary: "Taken the Frazzled test? This article breaks down what your results mean and how you can use them to improve your work-life balance and happiness.",
        date: "September 04, 2024",
        image: "/images/frazzledscore.jpg"
    },
    {
        id: 2,
        title: "Job Burnout: How Your Career Path Dictates the Pressure”",
        summary: "Not all jobs are created equal when it comes to burnout. Discover how different career paths impact stress levels and what you can do to manage it.",
        date: "September 03, 2024",
        image: "/images/burnout-3.jpg" 
    },
    {
        id: 3,
        title: "Frazzled vs. The Rest: Why This Review Site Is Different—and Better",
        summary: "What sets Frazzled apart from other review sites? Dive into the unique features that make it the go-to platform for understanding job satisfaction and burnout.",
        date: "September 02, 2024",
        image: "/images/glassdoor.jpg"
    },
    {
        id: 4,
        title: "Burnout Uncovered: What It Really Means and Why It’s Hitting Harder Than Ever",
        summary: "Burnout isn’t just a buzzword—it’s a serious condition affecting more people than ever. Learn what burnout truly entails and why it’s becoming increasingly common.",
        date: "September 01, 2024",
        image: "/images/burnout-1.jpg" 
    },
    {
        id: 5,
        title: "On the Hunt for Happiness: A Guide to Landing a Fulfilling Career",
        summary: "Dissatisfied with your current job? Explore practical steps to find a career that brings you joy and fulfillment, and escape the burnout trap.",
        date: "August 30, 2024",
        image: "/images/happy-1.jpg"
    },
    {
        id: 6,
        title: "Help Is Here: Where to Turn When Burnout Takes Over",
        summary: "Feeling overwhelmed by burnout? Discover the resources and support systems available to help you regain control and recover your well-being.",
        date: "August 29, 2024",
        image: "/images/therapy.jpg" 
    },
    {
        id: 7,
        title: "Spotting Burnout: Early Warning Signs You Should Never Ignore",
        summary: "Burnout often creeps up without warning. Recognize the subtle signs before they escalate and take control of your well-being before it’s too late.",
        date: "August 28, 2024",
        image: "/images/burnout-2.jpg" 
    },
    {
        id: 8,
        title: "Breaking the Burnout Cycle: How Communities Can Make a Difference",
        summary: "Tackling burnout isn’t just an individual effort—it’s a community challenge. Learn how collective action can help break the burnout cycle for good.",
        date: "August 27, 2024",
        image: "/images/teamwork-1.jpg"
    },
    {
        id: 9,
        title: "Frazzled: The New Buzzword for Modern Workplace Stress",
        summary: "Explore why “frazzled” has become the go-to term for describing the escalating stress and exhaustion plaguing today’s workforce, and what it means for you.",
        date: "August 26, 2024",
        image: "/images/darkjazzylogo.jpg" 
    },

];

const ArticleList = () => {
    return (
        <>
            <Helmet>
                <title>News - Frazzled</title>
                <meta name="description" content="Explore the latest articles on career development, job satisfaction, and more." />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="container mt-5">
                <h1 className="mb-4 pt-3">Latest News</h1>
                <div className="row">
                    {articles.map((article) => (
                        <div key={article.id} className="col-md-4 mb-4">
                            <Link to={`/news/${article.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="card h-100">
                                    <img src={article.image} className="card-img-top" alt={article.title} />
                                    <div className="card-body">
                                        <h5 className="card-title">{article.title}</h5>
                                        <p className="card-text">{article.summary}</p>
                                        <p className="card-text"><small className="text-muted">{article.date}</small></p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default ArticleList;