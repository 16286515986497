import React, { useState, useEffect } from 'react';
import CustomCircularProgressBar from './CustomCircularProgressBar';
import { useLocation, Link } from 'react-router-dom';
import queryString from 'query-string';
import { Helmet } from 'react-helmet-async';

const Results = () => {

    const { search } = useLocation();
    const { responseId } = queryString.parse(search);
    const { key } = queryString.parse(search);

    const [yourScore, setYourScore] = useState(50);
    const [jobTitleScore, setJobTitleScore] = useState(50);
    const [frazzledScore, setFrazzledScore] = useState(50);

    const [categoryResults, setCategoryResults] = useState([]);
    const [yourCategoryResults, setYourCategoryResults] = useState([]);
    const [frazzledResults, setFrazzledResults] = useState([]);

    const [frazzledCompanyResults, setFrazzledCompanyResults] = useState([]);
    const [yourCompanyResults, setYourCompanyResults] = useState([]);




    const fetchCategoryResults = async (jobTitleId) => {

        fetch('https://frazzled-api.azurewebsites.net/api/v1/statistics?jobTitleId=' + jobTitleId)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setJobTitleScore(data.categoryTotal)
                setFrazzledScore(data.frazzledTotal)
                setCategoryResults(data.categoryCategories)
                setFrazzledResults(data.frazzledCategories)
                setFrazzledCompanyResults(data.frazzledCompany)
            })
            .catch((error) => {
                //setError(error);
            });
    };

    useEffect(() => {

        fetch('https://frazzled-api.azurewebsites.net/api/v1/resultsstatistics?responseId=' + responseId + '&key=' + key)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                setYourScore(data.total)
                setYourCategoryResults(data.categories)
                setYourCompanyResults(data.categoriesCompany)
                fetchCategoryResults(data.jobTitleId);
            })
            .catch((error) => {
                //setError(error);
            });

    }, [responseId, key]);

    const categoryLookUp = {};
    categoryResults.forEach(category => {
        categoryLookUp[category.category] = category.value;
    });

    const frazzledLookUp = {};
    frazzledResults.forEach(category => {
        frazzledLookUp[category.category] = category.value;
    });

    const frazzledCompanyLookUp = {};
    frazzledCompanyResults.forEach(category => {
        frazzledCompanyLookUp[category.category] = category.value;
    });

    const cellColours = (value, isReverse) => {
        if (value > 70 && isReverse) {
            return 'red';
        } else if (value > 50 && isReverse) {
            return 'orange';
        } else if (isReverse) {
            return 'blue';

        } else if (value > 70 && !isReverse) {
            return 'blue';
        } else if (value > 50 && !isReverse) {
            return 'orange';
        } else if (!isReverse) {
            return 'red';

        } else {
            return '';
        }
    }

    return (
        <>
            <Helmet>
                <title>Your Results - Frazzled</title>
                <meta name="description" content="Analyse your burnout test results" />
                <meta name="keywords" content="happiness, career, work, burnout, job satisfaction, companies, reviews" />
            </Helmet>
            <div className="container-fluid">
                <div className="row justify-content-center" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 mt-5 mb-lg-5 mb-0">
                                    <div class="bg-white shadow-lg h-100 w-100 d-flex align-items-center justify-content-center p-4">
                                        <div className="text-center">
                                            <h3 className="w-100">Your Health Score</h3>
                                            <img src={`/batteries/${yourScore}.svg`} alt="battery-icon" className="my-3" style={{ maxHeight: 175 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 mt-lg-5 mt-3 mb-5">
                                    <div className="bg-white shadow-lg text-center w-100 mb-3 p-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="text-container me-5">
                                                <h3 className="text-title">Job Title<br />Average Score</h3>
                                            </div>
                                            <img src={`/batteries/${jobTitleScore}.svg`} alt="battery-icon" className="my-3 ms-3" style={{ maxHeight: 80 }} />
                                        </div>
                                    </div>
                                    <div className="bg-white shadow-lg text-center w-100 p-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="text-container me-5">
                                                <h3 className="text-title">Frazzled<br />Average Score</h3>
                                            </div>
                                            <img src={`/batteries/${frazzledScore}.svg`} alt="battery-icon" className="my-3 ms-3" style={{ maxHeight: 80 }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col mt-5 mb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h1>Your Mental Health Results</h1>
                                    <p>See how you compare to others with your job title and the whole community</p>

                                    <div className="table-responsive">
                                        <table className="table table-bordered" style={{ tableLayout: "fixed", width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center">Category</th>
                                                    <th className="fw-bold text-center">Your Score</th>
                                                    <th className="text-center">Job Title Average</th>
                                                    <th className="text-center">Frazzled Average</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {yourCategoryResults.map((item) => (
                                                    <tr>
                                                        <td className="text-center">{item.category}</td>
                                                        <td className={"fw-bold text-center " + cellColours(item.value, item.isReverse)}>{item.value}%</td>
                                                        <td className="text-center">{categoryLookUp[item.category]}%</td>
                                                        <td className="text-center">{frazzledLookUp[item.category]}%</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                    <div className="col">
                        <div className="container">
                            <div className="row">
                                <div className="col">

                                    <h1 className="mt-5 mb-4 text-left fw-bold">Company Experience</h1>
                                    <div className="row justify-content-center">

                                        {yourCompanyResults.map((item, index) => (
                                            <div key={index} className="col-lg-6 col-md-12 col-12 mb-4">
                                                <div className="bg-white p-xl-5 p-lg-3 p-5 shadow-lg text-center">
                                                    <CustomCircularProgressBar
                                                        title={item.category}
                                                        yourRating={item.value}
                                                        averageRating={frazzledCompanyLookUp[item.category]}
                                                    />
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col mt-5 mb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="row m-5 w-100">
                                    <div className="col-lg-12 col-12 d-flex flex-column justify-content-center text-center">
                                        <h1 className="d-none d-md-block" style={{ fontSize: '3.5em', marginLeft: '-0.2rem' }}>Discover more insights</h1>
                                        <h1 className="d-block d-md-none" style={{ fontSize: '2em', marginLeft: '-0.2rem', marginBottom: '2rem' }}>Discover more insights</h1>
                                        <h5 className="mb-4" style={{ marginTop: '-0.5rem' }}>Now you know how you feel, explore these categories to learn what makes a happy career.</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ backgroundImage: 'url("background.svg")', backgroundSize: "cover" }}>
                    <div className="col">
                        <div className="container">
                            <div className="row row-cols-2 row-cols-md-4 g-4 mt-5 mb-5">
                                <div className="col mb-4">
                                    <Link to="/search/JobTitle" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/person.svg" alt="Person" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Job Title</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Salary" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/dollarsign.circle.svg" alt="Salary Bracket" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Salary Bracket</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Age" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/birthday.cake.svg" alt="Age" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Age</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Department" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/building.columns.svg" alt="Department" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Department</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Gender" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="https://img.icons8.com/?size=75&id=1665&format=png&color=000000" alt="Gender" className="img-fluid" />
                                            <h5 className="mt-3 mb-3">Gender</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/Seniority" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/gauge.with.needle.svg" alt="Seniority" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Seniority</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/WorkingArrangement" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/house.svg" alt="Working Arrangement" className="img-fluid mt-3" />
                                            <h5 className="mt-3 mb-3">Working Arrangement</h5>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col mb-4">
                                    <Link to="/search/SexualOrientation" style={{ textDecoration: 'none', color: 'inherit' }}>
                                        <div className="bg-white p-3 shadow-lg text-center" style={{ boxSizing: 'border-box', height: '100%' }}>
                                            <img src="/icons/heart.svg" alt="Sexual Orientation" className="img-fluid mt-4" />
                                            <h5 className="mt-3 mb-3">Sexual Orientation</h5>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </>

    );
};

export default Results;
