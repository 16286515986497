import React from 'react';

const StatisticsCustomProgressBar = ({ title, yourRating, averageRating }) => {
  const barStyle = {
    height: '10px',
    borderRadius: '5px',
  };

  return (
    <div className="custom-progress-bar">
      <h4 className="mb-4">{title}</h4>

      <div className="progress-comparison-container">
        {/* Your Rating */}
        <div className="progress-container mb-2" style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ minWidth: '120px', fontWeight: 600 }}>Job Title</span>
          <div style={{ flexGrow: 1, backgroundColor: '#e0e0e0', borderRadius: '5px', overflow: 'hidden' }}>
            <div
              style={{
                ...barStyle,
                width: `${yourRating}%`,
                backgroundColor: '#ff8a5b',
              }}
            />
          </div>
          <span style={{ marginLeft: '10px', fontWeight: 600, width: '35px' }}>{yourRating}%</span>
        </div>

        {/* Average Rating */}
        <div className="progress-container" style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ minWidth: '120px', fontWeight: 400 }}>Average</span>
          <div style={{ flexGrow: 1, backgroundColor: '#e0e0e0', borderRadius: '5px', overflow: 'hidden' }}>
            <div
              style={{
                ...barStyle,
                width: `${averageRating}%`,
                backgroundColor: '#00bcd4',
              }}
            />
          </div>
          <span style={{ marginLeft: '10px', fontWeight: 400, width: '35px' }}>{averageRating}%</span>
        </div>
      </div>
    </div>
  );
};

export default StatisticsCustomProgressBar;