import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import Home from './components/Home';
import Browse from './components/Browse';
import Test from './components/Test';
import Results from './components/Results';
import Contact from './components/Contact';
import Privacy from './components/Privacy';
import Search from './components/Search.js';
import Statistics from './components/Statistics.js';
import ArticleList from './components/ArticleList';
import ArticlePage from './components/ArticlePage.js';


// Initialize Google Analytics
ReactGA.initialize('G-P47HB7WQEZ'); // Replace with your Google Analytics Measurement ID

function App() {
    const [navOpen, setNavOpen] = useState(false);

    const handleNavClick = () => {
        setNavOpen(!navOpen);
    };


    return (
        <HelmetProvider>
            <Router>
                {/* Call the page tracking hook inside Router */}
                <PageTracker />
                <div>
                    {/* Navbar */}
                    <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top p-3">
                        <div className="container d-flex justify-content-between align-items-center">
                            <Link className="navbar-brand" to="/">
                                <img src="logo-v2.svg" alt="logo" width="160" />
                            </Link>
                            <button
                                className="navbar-toggler"
                                onClick={handleNavClick}
                                type="button"
                                aria-controls="navbarSupportedContent"
                                aria-expanded={navOpen}
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div
                                className={"collapse navbar-collapse " + (navOpen ? "show" : "")}
                                id="navbarSupportedContent"
                            >
                                <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                                    <li className="nav-item dropdown me-3">
                                        <Link
                                            className="nav-item nav-link dropdown-toggle"
                                            to="/browse"
                                            onClick={handleNavClick}
                                            style={{ color: '#131212', fontWeight: '500', cursor: 'pointer', textDecoration: 'none' }}
                                        >
                                            Browse Results
                                        </Link>
                                        <ul className="dropdown-menu p-3" aria-labelledby="navbarDropdown">
                                            <p className="fw-semibold">View Results By:</p>
                                            <li><Link className="dropdown-item" to="/search/JobTitle" onClick={handleNavClick}>Job Title</Link></li>
                                            <li><Link className="dropdown-item" to="/search/Salary" onClick={handleNavClick}>Salary</Link></li>
                                            <li><Link className="dropdown-item" to="/search/Age" onClick={handleNavClick}>Age</Link></li>
                                            <li><Link className="dropdown-item" to="/search/Gender" onClick={handleNavClick}>Gender</Link></li>
                                            <li><Link className="dropdown-item" to="/search/Ethnicity" onClick={handleNavClick}>Ethnicity</Link></li>
                                            <li><Link className="dropdown-item" to="/search/SexualOrientation" onClick={handleNavClick}>Sexual Orientation</Link></li>
                                            <li><Link className="dropdown-item" to="/search/Department" onClick={handleNavClick}>Department</Link></li>
                                            <li><Link className="dropdown-item" to="/search/Seniority" onClick={handleNavClick}>Seniority</Link></li>
                                            <li><Link className="dropdown-item" to="/search/WorkingArrangement" onClick={handleNavClick}>Working Arrangement</Link></li>
                                            <li><Link className="dropdown-item" to="/search/YearsInService" onClick={handleNavClick}>Years in Service</Link></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item me-3">
                                        <Link to="/news" onClick={handleNavClick} className="nav-link" style={{ color: '#131212', fontWeight: '500' }}>
                                            News
                                        </Link>
                                    </li>
                                    <li className="nav-item me-2">
                                        <Link to="/contact" onClick={handleNavClick} className="nav-link" style={{ color: '#131212', fontWeight: '500' }}>
                                            Contact
                                        </Link>
                                    </li>
                                </ul>
                                <Link
                                    to="/test"
                                    onClick={handleNavClick}
                                    className="btn text-white fw-bold ms-3 shadow"
                                    style={{ backgroundColor: '#EA526F', width: 135 }}
                                >
                                    Take the Test
                                </Link>
                            </div>
                        </div>
                    </nav>

                    {/* Main Content */}
                    <div style={{ marginTop: '80px' }}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/Browse" element={<Browse />} />
                            <Route path="/test" element={<Test />} />
                            <Route path="/news" element={<ArticleList />} />
                            <Route path="/news/:id" element={<ArticlePage />} />
                            <Route path="/results" element={<Results />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route path="/search/:category" element={<Search />} />
                            <Route path="/statistics" element={<Statistics />} />
                        </Routes>
                    </div>

                    {/* Footer */}
                    <footer className="bg-dark d-flex flex-wrap justify-content-between align-items-center border-top p-5">
                        <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-light text-decoration-none">
                            <img src="logo-v2-dark.svg" className="ms-5" alt="logo" width="160" />
                        </a>
                        <ul className="nav col-md-4 justify-content-end me-4">
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link px-2 fw-bold me-3 text-light">Contact</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/privacy" className="nav-link px-2 fw-bold text-light">Privacy</Link>
                            </li>
                        </ul>
                        <p className="mb-0 text-light text-end col-12 col-md-auto me-4">© 2024 Frazzled</p>
                    </footer>
                </div>
            </Router>
        </HelmetProvider>
    );
}

// A component to handle page tracking
function PageTracker() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);

    return null;
}

export default App;